import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, {
  useContext, useState, useEffect,
  useRef,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { TvMain } from '@powdr/components';
import { AlertType, ThemeProps as Prop, StaticProfiles } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { getAlerts } from '@powdr/stores';
import {
  componentFactory, sortNavigationByWeight, themeUtil,
} from '@powdr/utils';

import GlobalStyle from '../../utils/global-styles';

const SiteWrapper = styled.div`
  height: 100vh;
  background-color: ${() => themeUtil(Prop.BACKGROUND, null, StaticProfiles.TV)};
`;

function TvLayout({
  data,
}) {
  const {
    defaultTheme,
  } = useContext(AppContext);
  const tvPages = {
    ...data.tvData,
    childPages: sortNavigationByWeight(
      data.tvData.relationships.pages.sort((a, b) => a.weight - b.weight),
    ),
  };
  const rotationData = (() => {
    const flatPages = [];
    const getFlatTvData = (childPages) => childPages.forEach((childPage) => {
      if (childPage.relationships?.pages?.length > 0) {
        if (childPage?.relationships?.tvComponents?.length > 0) {
          flatPages.push({
            name: (childPage?.displayTitle) ? childPage.displayTitle : childPage.name,
            link: childPage.link,
            displayTime: childPage.displayTime,
            components: [...childPage?.relationships?.tvComponents || []],
          });
        }
        getFlatTvData(childPage.relationships.pages.sort((a, b) => a.weight - b.weight));
      } else if (childPage?.relationships?.tvComponents?.length > 0) {
        flatPages.push({
          name: (childPage?.displayTitle) ? childPage.displayTitle : childPage.name,
          link: childPage.link,
          displayTime: childPage.displayTime,
          components: [...childPage?.relationships?.tvComponents || []],
        });
      }
    });
    getFlatTvData(tvPages.childPages);

    return flatPages;
  })();

  const dispatch = useDispatch();
  const useAlertData = useSelector((state) => state.alerts);
  const { data: alerts } = useAlertData;
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeData, setActiveData] = useState(rotationData[0]);
  const [isAlertOverlayActive, setAlertOverlayActive] = useState(false);
  const [pauseRotation, setPauseRotation] = useState(isAlertOverlayActive || false);
  // const [activeComponentTimestamp, setActiveComponentTimestamp] = useState();
  const rotationTimeoutRef = useRef();
  const alertIntervalRef = useRef();
  const alertIntervalFrequency = 3; // minutes

  const getNextSlideIndex = useCallback(
    () => ((activeIndex + 1 === rotationData.length) ? 0 : activeIndex + 1),
    [activeIndex, rotationData.length],
  );

  const getPreviousSlideIndex = useCallback(
    () => ((activeIndex === 0) ? rotationData.length - 1 : activeIndex - 1),
    [activeIndex, rotationData.length],
  );

  const handleNextItem = useCallback(() => {
    setActiveIndex(getNextSlideIndex());
    setActiveData(rotationData[getNextSlideIndex()]);
  }, [getNextSlideIndex, rotationData]);

  const handlePreviousItem = useCallback(() => {
    setActiveIndex(getPreviousSlideIndex());
    setActiveData(rotationData[getPreviousSlideIndex()]);
  }, [getPreviousSlideIndex, rotationData]);

  // TV rotation
  useEffect(() => {
    rotationTimeoutRef.current = setTimeout(() => {
      if (!pauseRotation) {
        handleNextItem();
      }
    }, rotationData[activeIndex].displayTime * 1000);

    return () => {
      clearTimeout(rotationTimeoutRef.current);
    };
  }, [activeIndex, handleNextItem, rotationData, pauseRotation]);

  /* Keep these for debugging */
  useEffect(() => {
    if (pauseRotation) {
      console.log('TV rotation paused');
    } else {
      console.log('TV rotation active');
    }
  }, [pauseRotation]);

  // Get alerts on interval, kick off on component load
  useEffect(() => {
    dispatch(getAlerts());

    alertIntervalRef.current = setInterval(() => {
      dispatch(getAlerts());
    }, alertIntervalFrequency * 60000);

    return () => {
      clearInterval(alertIntervalRef.current);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle alert overlay and rotation pausing when needed
  useEffect(() => {
    if (alerts?.find((a) => a.type === AlertType.DANGER)) {
      setAlertOverlayActive(true);
      setPauseRotation(true);
    } else {
      if (pauseRotation) setPauseRotation(false);
      if (isAlertOverlayActive) setAlertOverlayActive(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerts]);

  return (
    <>
      <GlobalStyle theme={defaultTheme} />
      <SiteWrapper>
        <TvMain
          title={activeData.name}
          navPages={tvPages.childPages}
          alternateTVLogo={tvPages.alternateTVLogo}
          // lastUpdatedTime="11:30 AM"
          activeItemData={activeData}
          setPauseState={setPauseRotation}
          pauseState={pauseRotation}
          nextItem={handleNextItem}
          previousItem={handlePreviousItem}
          isAlertOverlayActive={isAlertOverlayActive}
          overlayAlerts={(alerts?.length > 0)
            ? alerts?.filter((a) => a.type === AlertType.DANGER)
            : []}
        >
          {componentFactory(
            activeData.components || [],
            undefined,
            undefined,
            {
              displayTime: activeData.displayTime,
              skip: handleNextItem,
              alerts: (alerts?.length > 0) ? alerts : [],
              alertQrCode: tvPages.relationships?.alertQRCode?.relationships?.image?.gatsbyImage,
            },
          )}
        </TvMain>
      </SiteWrapper>
    </>
  );
}

TvLayout.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default TvLayout;

export const query = graphql`
  query TvLayout($fullPath: String){
    site {
      ...SiteMetadataQuery
    }
    tvData: taxonomyTermTvNavigation( field_navigation_link: { eq: $fullPath } ) {
      ...TvNestedPagesQuery
    }
  }
`;
